body {
    background-image: url('../../public/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.hidden {
    display: none;

}

.logobec {
    width: 300px;
    transition: 1s;
}

.logobec:hover {
    scale: 1.1;
    transition: 1;
}

h1 {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
}

h1 span {
    color: black;
    padding-top: 15vh;
    font-size: 23px;
    position: relative;
    left: -97px;
    top: -30px;
    font-weight: normal;
}

h1 img {
    position: relative;
    z-index: inherit;
}

.firstline {
    display: flex;
    flex-direction: column;
    padding-left: 15%;
    letter-spacing: 1.5px;
    font-size: 20px;
    line-height: 50px;
}

img {
    width: 150px;
}

.secondline {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 60px;
    padding-top: 75px;
}

.secondline>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 20px;
}

.secondline img {
    width: 300px;
    height: 300px;
    margin-right: 100px;
    border-radius: 50%;
    box-shadow: -3.5px 5px 7px 1px #8d8787;
    margin-bottom: 10px;
    transition: all 0.5s ease-in-out;
}

.link {
    text-decoration: none;
    color: inherit;
    text-decoration: none;
    color: black;
    font-size: 1.4em;
    position: relative;
    padding: 0 15px;
    transition: .2s all;
    font-weight: 300;
}

.link::before {
    content: '';
    position: absolute;
    background: #ec7506;
    width: 2px;
    height: 100%;
    left: 0;
    transition: .2s all;
    z-index: -1;
}

.link:hover::before {
    width: 100%;
}

.link:hover {
    color: #ffffff;
    font-size: 1.6em;
}

h2 {
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    max-width: 220px;
    margin-left: -30%;
}



.footer {
    text-align: center;
    padding-top: 15px;
    font-size: 15px;
}

.footer-red {
    text-align: center;
    padding-top: 5px;
    font-size: 13px;
    color: red;
    padding-bottom: 10px;
}

.page {
    display: flex;
    justify-content: right;
    padding-right: 100px;
    padding-top: 20px;
}

/* Responsive Design */

@media (max-width: 1200px) {
    .secondline img {
        width: 250px;
        height: 250px;
        margin-right: 50px;
    }

    .meca {
        margin-right: 100px;
    }

    h1 span {
        left: -50px;
        top: -20px;
    }

    .page {
        padding-right: 50px;
    }
}

@media (max-width: 768px) {
    .firstline {
        padding-left: 10%;
        font-size: 18px;
    }


    .secondline img {
        width: 200px;
        height: 200px;
        margin-right: 0;
    }

    h1 span {
        font-size: 20px;
        left: 0;
        top: 0;
        padding-top: 10vh;
    }

    h2 {
        font-size: 12px;
        margin-left: 0;
    }

    .page {
        justify-content: center;
        padding-right: 0;
    }
}

@media (max-width: 480px) {
    h1 span {
        font-size: 18px;
    }

    .firstline {
        font-size: 16px;
        line-height: 40px;
    }

    .firstline li {
        padding: 15px;
    }

    .link::before {
        content: none;

    }



    .secondline img {
        width: 150px;
        height: 150px;
    }

    .link {
        font-size: 0.8em;
        padding: 10px;
        text-indent: 20px;
    }

    .link:hover {
        font-size: 0.9em;
        color: black;
    }

    .footer,
    .footer-red {
        font-size: 12px;
    }


}