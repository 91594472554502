body {
    background-image: url('../../public/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
}


h1 {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
}

h1 span {
    color: black;
    padding-top: 15vh;
    font-size: 23px;
    position: relative;
    left: -97px;
    top: -30px;
    font-weight: normal;
}

h1 img {
    position: relative;
    z-index: inherit;
}

.presentation {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.presentation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
}

.presentation-description {
    font-size: 18px;
    padding: 10px 150px;
    width: 90%;

}

.presentation-description1 {
    font-size: 18px;
    padding: 10px 150px;
    width: 90%;


}

@keyframes fadeIn {
    from {
        opacity: 0;



    }

    to {
        opacity: 1;
        transform: translateX(0px);

    }
}

.presentation-description {
    padding-top: 30px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 30px;
    font-size: 25px;
    font-weight: 400;
    transform: translateX(-1000px) translateY(150px);

    animation-name: fadeIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.footer-etude {
    text-align: center;
    padding-top: 40px;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;

}


.info {
    padding-top: 30px;
    padding-left: 25px;
    padding-bottom: 20px;
}

.info a {
    text-decoration: none;
    color: black;


}

.splide-container {

    padding-top: 150px;
    padding-left: 150px;
    padding-right: 150px;
}

.splide-container img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
}

@media (max-width: 768px) {
    .splide-container {

        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .splide-container img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 15px;
    }

}

@media (max-width: 1200px) {
    .presentation-description {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .presentation-container {
        padding-right: 300px;
    }

    .presentation-description {
        font-size: 14px;
        padding: 8px 0;
    }
}

@media (max-width: 480px) {
    .presentation {
        font-size: 20px;
        width: 75%;
        padding: 6px 40px;
        text-align: center;
    }

    .presentation-description1 {
        font-size: 18px;
        width: 75%;
        padding: 6px 60px
    }

    .presentation-description {
        font-size: 12px;
        width: 75%;
        padding: 20px 10px;
    }


}