body {
    background-image: url('../../public/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.partenaire {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

}

h1 {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
}

h1 span {
    color: black;
    padding-top: 15vh;
    font-size: 23px;
    position: relative;
    left: -97px;
    top: -30px;
    font-weight: normal;
}

h1 img {
    position: relative;
    z-index: inherit;
}

.intro {
    padding-left: 20px;
    letter-spacing: 1.5px;
    padding-top: 20px;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
    line-height: 50px;
}

.prestation-page {
    display: flex;
    flex-direction: column;
    padding: 50px;
    padding-left: 100px;

}

.services-section,
.engagement-section {

    padding: 20px;

}

.services-section h2,
.engagement-section h2 {
    padding-left: 400px;
    font-size: 1.5em;
    font-weight: 10px;
}

.services-section ul,
.engagement-section ul {
    list-style: none;
    padding-left: 0;
}

.services-section li,
.engagement-section li {
    margin-bottom: 10px;
    padding-top: 15px;
}

.info {
    padding-top: 30px;
    padding-left: 25px;
    padding-bottom: 20px;
}

.info a {
    text-decoration: none;
    color: black;
}

/* Responsive Design */

@media (max-width: 1200px) {
    h1 span {
        left: -50px;
        top: -20px;
    }

    .services-section h2,
    .engagement-section h2 {
        padding-left: 200px;
    }
}

@media (max-width: 768px) {
    .intro {
        font-size: 18px;
        line-height: 40px;
    }

    h1 span {
        font-size: 20px;
        left: 0;
        top: 0;
        padding-top: 10vh;
    }

    .services-section h2,
    .engagement-section h2 {
        padding-left: 50px;
        font-size: 1.3em;
    }

    .info {
        padding-left: 10px;
    }
}

@media (max-width: 480px) {
    h1 span {
        font-size: 18px;
    }

    .intro {
        font-size: 16px;
        line-height: 30px;
    }

    .services-section,
    .engagement-section {
        padding: 10px;
    }

    .services-section h2,
    .engagement-section h2 {
        padding-left: 20px;
        font-size: 1.2em;
    }

    .info {
        padding-left: 5px;
    }
}