body {
    background-image: url('../../public/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

h1 {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
}

h1 span {
    color: black;
    padding-top: 15vh;
    font-size: 23px;
    position: relative;
    left: -97px;
    top: -30px;
    font-weight: normal;
}

h1 img {
    position: relative;
    z-index: inherit;
}

.special-title {
    text-align: center;
    padding-top: 20px;
    font-size: 28px;
}

.special-description {
    text-align: center;
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 10px;
    font-size: 19px;
}

ul {
    list-style-type: none;
}

.special-offer {
    padding-top: 70px;
    font-size: 19px;
    text-align: left;
}

.special-offer li {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 19px;
}

.special-sectors {
    text-align: left;
    font-size: 19px;
    padding-top: 40px;
}

.special-approach {
    text-align: left;

    padding-left: 50px;
    padding-top: 50px;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
}

.special-flex {
    display: flex;
}

.special-footer {
    text-align: center;
    padding-top: 70px;
    font-size: 18px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 25px;
}

/* Responsive Design */

@media (max-width: 1200px) {
    h1 span {
        left: -50px;
        top: -30px;
    }

    .special-description {
        padding-left: 50px;
        padding-right: 50px;
    }

    .special-footer {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (max-width: 768px) {
    h1 span {
        font-size: 20px;
        left: 0;
        top: 0;
        padding-top: 10vh;
    }

    .special-title {
        font-size: 24px;
    }

    .special-description {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 17px;
    }

    .special-offer,
    .special-sectors,
    .special-approach,
    .special-footer {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 17px;
    }

    .special-approach {
        padding-left: 20px;
        font-size: 22px;
    }

    .special-footer {
        font-size: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 480px) {
    h1 span {
        font-size: 18px;
    }

    .special-title {
        font-size: 22px;
    }

    .special-description {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
    }

    .special-offer,
    .special-sectors,
    .special-approach,
    .special-footer {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;
    }

    .special-approach {
        padding-left: 10px;
        font-size: 20px;
    }

    .special-footer {
        font-size: 18px;
        padding-left: 20px;
        padding-right: 20px;
    }
}